.player-root {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &--video {
    width: 100%;
    height: 100%;
    cursor: pointer;
    &-play-disabled {
      cursor: inherit;
    }
  }

  &--spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9999;
  }
}

.player-view {
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover &--subtitles[data-state='controls-hover'] {
    -webkit-transform: translateY(0px)!important;
    transform: translateY(0px)!important;
    transition: transform 0.3s ease-in-out 0s;
  }

  &--video {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &--subtitles {
    display: block;
    position: absolute;
    z-index: 6;
    background: rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
    bottom: 80px;
    pointer-events: none;
    color: #ffffff;
    transition: transform 0.3s ease-in-out 0s;
    &[data-state='controls-hidden'] {
      -webkit-transform: translateY(70px);
      transform: translateY(70px);
      transition: transform 0.3s ease-in-out 0.2s;
    }
    &[data-state='controls-hover'] {
      -webkit-transform: translateY(70px);
      transform: translateY(70px);
      transition: transform 0.3s ease-in-out 0.2s;
    }
  }
  &--interactivity {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    pointer-events: none;
    & * {
      position: fixed;
      z-index: 5;
      pointer-events: all;
    }
    & button, a {
      cursor: pointer;
    }
    .quiz-question {
      margin: 0;
    }
    .quiz-submit-button {
      background: #4868ff!important;
      &:disabled {
        background: rgba(217, 217, 217, 0.2)!important;
        color: #BDBDBD!important;
        border-color: #979797!important;
      }
    }
    .answer-control-native {
      display: contents;
    }
  }
  &--controls {
    position: absolute;
    z-index: 6;
    left: 0;
    right: 0;
    bottom: 0;
    .button {
      &.disabled {
        cursor: auto;
        background: rgba(151, 151, 151, 1);
        svg {
          color: rgba(91, 91, 91, 1);
        }
      }
      background-color: #ffffff;
      border-radius: 4px;
      padding: 3px 6px;
      display: flex;
      svg {
        height: 9px;
      }
    }
    .disabled-icon {
      cursor: auto;
      svg {
        path {
          fill: #979797;
        }
      }
    }
  }
}

