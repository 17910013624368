/* HOW TO
  .selector {
    display: flex;
    .media-max(960px, {
      display: none;
    });
  }
*/
.media-max(@break_point; @rules) {
  @media screen and (max-width: @break_point) {
    @rules();
  }
}

.media-min(@break_point; @rules) {
  @media screen and (min-width: @break_point) {
    @rules();
  }
}

.media-range(@break_point_from; @break_point_to; @rules) {
  @media screen and (min-width: @break_point_from) and (max-width: @break_point_to) {
    @rules();
  }
}
