.quiz-fieldset {
  border: none;
  display: block;
  overflow-y: auto;
  padding-block-end: 0;

  .answer-text {
    display: block;
    position: absolute;
    pointer-events: none;
  }

  .quiz-answer {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
  }

  .answer-control {
    display: block;
    position: absolute;
    transition: 0.3s ease-in-out 0.1s;
    pointer-events: none;
  }
}

.answer-control-native {
  &:focus {
    outline: none;
  }
  &:focus + .answer-control {
    outline: none;
    border: 1px solid #000;
  }
}

