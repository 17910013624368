.play-pause-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 9999;
  pointer-events: none;
  background-color: rgba(0,0,0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  svg {
    path {
      fill: #6b6b6b;
    }
  }
}

