@import './mixins.less';
@import './variables.less';
@import url('https://fonts.cdnfonts.com/css/basier');

html {
  font-family: 'Basier Circle', Calibri, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.interactive-player {
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  z-index: 2;
  .volume-wrapper:hover ~ .time-wrapper {
    .media-max(@xs, {
      display: none;
    });
  }
  video {
    max-height: 100vh;
  }
  video::-webkit-media-text-track-display {
    opacity: 0;
  }
  &:hover .controls-wrapper > .controls[data-state='hover'] {
    bottom: 0;
    transition: 0.5s ease-in-out;
  }
  &:hover #controls-collapse-button {
    bottom: 70px;
    transition-delay: 0s;
  }
  &:fullscreen {
    display: flex;
    align-items: center;
    .three-dot-menu {
      bottom: 50px !important;
    }
  }
  &-menu {
    z-index: 10 !important;
    .ant-dropdown {
      &-arrow:before {
        background: rgba(20, 20, 20, 0.75);
      }
      &-menu {
        background: rgba(20, 20, 20, 0.75);
        .ant-dropdown-menu-item {
          color: #ffffff;
          &-group-title {
            padding: 0;
            .item-group-submenu {
              padding: 5px 12px;
              .expand-icon {
                display: flex;
                justify-content: flex-end;
                flex: 1 1 auto;
              }
            }
          }
          .submenu {
            display: flex;
            gap: 10px;
            .normal {
              svg {
                height: 0;
              }
            }
          }
        }
        &-title-content {
          color: #ffffff;
          display: flex;
          align-items: center;
          gap: 8px;
        }
        &-submenu-arrow-icon {
          color: #ffffff !important;
        }
      }
    }
    &:hover ~ #controls {
      bottom: 0;
    }
  }

  &-menu.chapter-menu {
    .ant-dropdown-menu {
      li:first-child {
        pointer-events: none;
        div {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0;
        }
      }
      li:not(:first-child) {
        div {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0;
          display: flex;
          gap: 8px;
          span:first-child {
            color: rgba(189, 189, 189, 1);
            width: 30px;
          }
        }
      }
    }
  }
  .ant-slider-rail {
    background: rgba(217, 217, 217, 40%);
  }
  .ant-slider-track {
    background-color: #ffffff;
  }
  .ant-slider-with-marks {
    margin-bottom: 6px;
  }
  .ant-slider-mark {
    &-text-active {
      .interactive-mark {
        background-color: #c2d2ff !important;
      }
    }
    &-text {
      .mark-chapter,
      .interactive-mark {
        position: absolute;
        left: -6px;
      }
      .mark-chapter {
        background-color: transparent;
        width: 10px;
        height: 10px;
        top: -10px;
      }
      .interactive-mark {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        top: -14px;
        background-color: #ffffff;
        border-radius: 4px;
        transform: rotate(45deg);
        &:hover {
          background-color: #c2d2ff;
        }
        svg {
          transform: rotate(-45deg);
        }
        .media-max(@xs, {
          width: 14px;
          height: 14px;
          top: -13px;
          border-radius: 3px;
          svg {
            width: 12px;
            height: 12px;
          }
        })
      }
    }
  }
  .ant-slider-dot {
    width: 6px;
    height: 4px;
    margin-top: 2px;
    border-radius: 0;
    background-color: #ffffff;
  }
  .controls-wrapper {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    z-index: 4;
    .controls {
      padding-top: 10px;
      pointer-events: auto;
      position: absolute;
      right: 0;
      left: 0;
      bottom: -90px;
      transition: bottom 0.5s ease-in-out;
      background: linear-gradient(0deg, rgb(30, 30, 30, 0.8), #333 20%, rgba(51, 51, 51, 0));
      .ant-slider-handle {
        display: none;
      }
      .ant-slider-track {
        transition: width 0.2s ease;
      }
      &[data-state='visible'] {
        bottom: 0;
      }
      &[data-state='hover'] {
        bottom: -90px;
      }
      &[data-state='hidden'] {
        bottom: -90px;
      }
    }
  }
  .current-time-popup {
    position: absolute;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.85);
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 10px 20px;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    .right {
      gap: 10px;
      color: #ffffff;
      .media-max(@xs, {
        gap: 4px;
      });
      button {
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .ant-slider-handle {
        display: none;
      }
    }
    .left {
      gap: 20px;
      .play {
        svg {
          height: 20px;
          width: 20px;
          path {
            transition: 1s;
          }
        }
      }
      .button {
        &.disabled {
          cursor: unset !important;
          background: rgba(151, 151, 151, 1);
          svg {
            color: rgba(91, 91, 91, 1);
          }
        }
        background-color: #ffffff;
        border-radius: 4px;
        padding: 3px 6px;
        svg {
          height: 9px;
        }
      }
      .media-max(@xs, {
        gap: 10px;
      })
    }
  }
  .progress-wrapper {
    padding: 0 16px;
  }
  .volume-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    &:hover > button > .ant-slider {
      width: 100px;
      margin: 11px 15px 11px 5px;
    }
    button {
      display: flex;
      border-radius: 20px;
      align-items: center;
      padding: 4px 6px;
      .ant-slider {
        width: 0;
        margin: 11px 0 11px 0;
        transition:
          width 0.2s ease-in,
          margin-right 0.2s ease-in;
      }
    }
  }
  .time-wrapper {
    font-family: Calibri, Arial, sans-serif;
    .media-max(@xs, {
      font-size: 14px;
      order: -1;
    });
  }
  .controls-collapse-button {
    position: absolute;
    right: 20px;
    bottom: 80px;
    background: rgba(12, 13, 13, 1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    svg {
      width: 10px;
      height: 10px;
    }
    &:hover {
      background-color: #606060 !important;
    }
    &[data-state='controls-hidden'] {
      bottom: 20px !important;
      transition: bottom 0.5s;
    }
    &[data-state='controls-visible'] {
      bottom: 80px;
    }
    &[data-state='controls-hover'] {
      bottom: 20px;
      transition: bottom 0.5s;
    }
  }
  .answer-container-button_send {
    &:disabled {
      color: #bdbdbd;
      background: rgba(217, 217, 217, 0.2);
      border-color: #979797;
    }
  }
}
.centered-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 500;
}

.app-message {
  .app-message-notice-wrapper {
    .app-message-notice-content {
      background: rgba(49, 53, 59, 0.95);
      box-shadow: 4px 4px 20px 0 rgba(49, 53, 59, 0.2);
      font-family: Basier Circle;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      text-align: left;
      padding: 8px 16px;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.disabled-icon {
  svg {
    path {
      fill: #979797;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 2px;
  background-color: #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #d2d2d2, #e3e3e3);
  border-radius: 15px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

